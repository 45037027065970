import * as React from "react";
import { Link } from "gatsby";
import { useMutation, gql } from "@apollo/client";

import { GET_USER } from "../hooks/useAuth";

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: {
      login: $login
      password: $password
    }) {
      status
    }
  }
`;

export default function LogInForm() {
  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const errorMessage = error?.message || '';
  const isEmailValid =
    !errorMessage.includes('empty_email') &&
    !errorMessage.includes('empty_username') &&
    !errorMessage.includes('invalid_email') &&
    !errorMessage.includes('invalid_username');
  const isPasswordValid =
    !errorMessage.includes('empty_password') &&
    !errorMessage.includes('incorrect_password');

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const { email, password } = Object.fromEntries(data);
    logIn({
      variables: {
        login: email,
        password,
      }
    }).catch(error => {
      console.error(error);
    });
  }

  return (
    <form method="post" onSubmit={handleSubmit} className="p-contact-form">
      <fieldset disabled={loading} aria-busy={loading}>
        <dl>
          <div>
            <dt>
              <label htmlFor="log-in-email">Email</label>
              </dt>
            <dd>
            <input
          id="log-in-email"
          type="email"
          name="email"
          autoComplete="username"
          required
        />
            </dd>
          </div>
          <div>
            <dt>
            <label htmlFor="log-in-password">Password</label>
            </dt>
            <dd>
            <input
          id="log-in-password"
          type="password"
          name="password"
          autoComplete="current-password"
          required
        />
        {/* <p>
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot password?
          </Link>
        </p> */}
            </dd>
          </div>
        </dl>
        {!isEmailValid ? (
          <p className="error-message">Invalid email. Please try again.</p>
        ) : null}
        {!isPasswordValid ? (
          <p className="error-message">Invalid password. Please try again.</p>
        ) : null}
        <button type="submit" disabled={loading} className="c-btn--primary">
          {loading ? 'Logging in...' : 'Log in'}
        </button>
      </fieldset>
      {/* <p className="account-sign-up-message">
        Don&#39;t have an account yet?{' '}
        <Link to="/sign-up">
          Sign up
        </Link>
      </p> */}
    </form>
  );
}
