import * as React from "react";

import Layout from "../components/Layout";
import UnAuthContent from "../components/UnAuthContent";
import LogInForm from "../components/LogInForm";

const LogIn = ()=> {
  return (
    <Layout pageTitle="Login" pageSlug="log-in">
      <UnAuthContent>
        <LogInForm />
      </UnAuthContent>
    </Layout>
  );
}

export default LogIn;
